import React from 'react'
import productLg6 from "../../assets/images/LM500.jpg";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';


function Product_Lg6() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/80'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productLg6} alt="" /></Link>
    <div className='box'><h6 className='title'>TV LG</h6>
    
    <Link to='/shop/80'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link></div>
  </div>
  )
}

export default Product_Lg6