import React from 'react'
import productHs6 from "../../assets/images/REF20SA.jpg";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';

function Product_Hs6() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/145'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productHs6} alt="" /></Link>
    <h6 className='title'>Réfrigerateur Hisense</h6>
    
    <Link to='/shop/145'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link>
  </div>
  )
}

export default Product_Hs6