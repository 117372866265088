


import React, { useState, useRef, useEffect } from 'react';

import { Container, Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import products from '../assets/data/products';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/Ul/CommonSection';
import '../styles/product-detail.css';
import { motion } from 'framer-motion';
import ProductsList from '../components/Ul/ProductsList';
import { useDispatch } from 'react-redux';
import { cartActions } from '../redux/slices/cartSlice';
import { toast } from 'react-toastify';
import Product_marqueCorredo1 from '../components/Product-Slider/Product_marqueCorredo';
import Product_marqueDorco1 from '../components/Product-Slider/Product_marqueDorco';
import Product_marqueHisense1 from '../components/Product-Slider/Product_marqueHisense';
import Product_marqueLg1 from '../components/Product-Slider/Product_marqueLg';
import Product_marqueNutribom1 from '../components/Product-Slider/Product_marqueNutribom';
import Product_marquePaco1 from '../components/Product-Slider/Product_marquePaco';
import Product_marqueEnzahome1 from '../components/Product-Slider/Product_marqueEnzahome';
import Carousel1 from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Showroom from '../components/Showroom/Showroom';

const ProductDetails = () => {

  const [tab, setTab] = useState('desc');
  const reviewUser = useRef("");
  const reviewMsg = useRef("");
  const dispatch = useDispatch();


  const [rating,setRating] = useState(null);
  const { id } = useParams();
  const product = products.find(item => item.id === id);


const { 
   imgUrl,
   productName, 
   price, 
   avgRating, 
   reviews, 
   description, 
   shortDesc, 
   category,
   slider,
  } = product;



  const relatedProducts = products.filter(item => item.category === category);

  const submitHandler = (e) => {
    e.preventDefault();

    const reviewUserName = reviewUser.current.value;
    const reviewUserMsg = reviewMsg.current.value;
    //console.log(reviewUserName ,reviewMsg);
    const reviewObj = {
      userName: reviewUserName,
      text: reviewUserMsg,
      rating,
    };
    console.log(reviewObj);
    toast.success('Soumettre');
  };


  const addToCart =()=> {
    dispatch(
      cartActions.addItem({
      id, 
      image:imgUrl, 
      productName, 
      price, 
    }) 
    );

    toast.success("Il est ajouté dans la panier");
  }; 

  useEffect(() => {
    window.scroll(0, 0);
  }, [product]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };


  return (
    <Helmet title={productName}>
      {/** <Audio /> */}

      <CommonSection title={slider}/>

      <section className='pt-0'>
        <Container>
          <Row>
            <Col lg='6'>
              <img src={imgUrl} alt="" />
            </Col>

            <Col lg='6'>
              <div className="product__details">
                <h2>{productName}</h2>
                <div className="product__rating d-flex align-items-center
                 gap-5 mb-3">
                  <div>
                    <span>
                      <i className='ri-star-s-fill'></i>
                    </span>
                    <span>
                      <i className='ri-star-s-fill'></i>
                    </span>
                    <span>
                      <i className='ri-star-s-fill'></i>
                    </span>
                    <span>
                      <i className='ri-star-s-fill'></i>
                    </span>
                    <span>
                      <i className='ri-star-half-s-line'></i>
                    </span>
                  </div>

                  <p>(<span>{avgRating}</span>rating) </p>
                </div>
                
                <div className='d-flex align-items-center gap-5'>
                <span className='product__price'>${price}</span>
                <span>Category: {category.toUpperCase()}</span>
                </div>
                <p className='mt-3'>{shortDesc}</p>

                <motion.button 
                whileTap={{ scale:1.2 }} 
                className='buy__btn' 
                onClick={addToCart}
                >
                  Ajouter dans le panier
                </motion.button> <br /> <a href="https://api.whatsapp.com/send?phone=243858955999"><button className='buy__btn'>Contactez-nous <i class="ri-whatsapp-line lien" ></i></button></a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg='12'>
              <div className="tab__wrapper d-flex align-items-center 
              gap-5">
                <h6 
                  className={`${tab==="desc" ? "active__tab" : ""}`}
                  onClick={()=> setTab('desc')}
                >
                  Description
                </h6>
                <h6 
                  className={`${tab==="rev" ? "active__tab" : ""}`}
                  onClick={() => setTab('rev')}
                >
                  Reviews ({reviews.length})
                </h6>
              </div>

              {tab === 'desc' ? (
              <div className="tab__content mt-5">
                <p>{description}</p>
              </div>
               ) : (
                <div className='product_review mt-5'>
                  <div className="review__wrapper">
                    <ul>
                      {reviews?.map((item, index) => (
                          <li kew={index} className='mb-4'>
                            <h6>Boanege Bwalo</h6>
                          <span>{item.rating} (rating)</span>
                          <p>{item.text}</p>
                          </li>
                        ))
                      }
                    </ul>

                    <div className="review__form">
                      <h4>Laisser votre expérience</h4>
                      <form action="" onSubmit={submitHandler}>
                        <div className="form__group">
                          <input 
                          type="text" 
                          placeholder='Enter Name' 
                          ref={reviewUser}
                          required/>
                        </div>

                        <div className="form__group d-flex align-items-center gap-5 
                        rating__group">
                          <motion.span whileTap={{ scale:1.2 }} onClick={()=> setRating(1)}>
                            1<i className='ri-star-s-fill'></i>
                          </motion.span>
                          <motion.span whileTap={{ scale:1.2 }} onClick={()=> setRating(1)}>
                            2<i className='ri-star-s-fill'></i>
                          </motion.span>
                          <motion.span whileTap={{ scale:1.2 }} onClick={()=> setRating(1)}>
                            3<i className='ri-star-s-fill'></i>
                          </motion.span>
                          <motion.span whileTap={{ scale:1.2 }} onClick={()=> setRating(1)}>
                            4<i className='ri-star-s-fill'></i>
                          </motion.span>
                          <motion.span whileTap={{ scale:1.2 }} onClick={()=> setRating(1)}>
                            5<i className='ri-star-s-fill'></i>
                          </motion.span>
                        </div>

                        <div className="form__group">
                          <textarea 
                          ref={reviewMsg}
                          rows={4} 
                          type="text" 
                          placeholder='Review Message....'
                          required />
                        </div>
                        <motion.button whileTap={{ scale:1.2 }} type='submit' className="buy__btn">
                          Submit
                        </motion.button>
                      </form>
                    </div>
                  </div>
                </div>
               )}
            </Col>

            <Col lg='12' className='mt-5'>
              <h2 className='related__title'>Vous aimerez aussi</h2>
            </Col>

            <ProductsList data={relatedProducts} />
          </Row>
        </Container>
      </section>
      <section className="trending__products hisense">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title titre'> Nos Marques</h2>
          </Col>
          <Carousel1 responsive={responsive} className='text-center'>
             <Product_marqueHisense1 />
             <Product_marqueCorredo1 />
              <Product_marqueEnzahome1 />
              <Product_marqueDorco1 />
              <Product_marqueLg1 />
              <Product_marqueNutribom1 />
              <Product_marquePaco1 />
              <Product_marqueHisense1 />          
           </Carousel1>
        </Row>
      </Container>
    </section>  
    </Helmet>
  );
 
};

export default ProductDetails;