
import React, {useState, useEffect} from 'react';
import Image from 'react-bootstrap/Image';
import '../styles/shop.css';


import products from '../assets/data/products';
//courel
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from "../assets/images/20240530-banner.jpg";
import ExampleCarouselImage2 from "../assets/images/20240603-banner.jpg";
import ExampleCarouselImage3 from "../assets/images/hisense-88l5v-sonic-screen-laser-tv.jpg";

import 'react-multi-carousel/lib/styles.css';

import Helmet from '../components/Helmet/Helmet';
import '../styles/home.css';

import { Container, Row, Col } from 'reactstrap';


import ProductsList from '../components/Ul/ProductsList';

import Product_marqueCorredo1 from '../components/Product-Slider/Product_marqueCorredo';
import Product_marqueDorco1 from '../components/Product-Slider/Product_marqueDorco';
import Product_marqueHisense1 from '../components/Product-Slider/Product_marqueHisense';
import Product_marqueLg1 from '../components/Product-Slider/Product_marqueLg';
import Product_marqueNutribom1 from '../components/Product-Slider/Product_marqueNutribom';
import Product_marquePaco1 from '../components/Product-Slider/Product_marquePaco';
import Product_marqueEnzahome1 from '../components/Product-Slider/Product_marqueEnzahome';
import Carousel1 from 'react-multi-carousel';
import Showroom from '../components/Showroom/Showroom';


import { LuListFilter } from "react-icons/lu";

const Hisense = () => {
  const [productsData, setProductsData] = useState(products)
  const handleFilter = (e)=>{
    const filterValue = e.target.value;
    if (filterValue === "Hisense audio") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense audio"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "Hisense tv") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense tv"
        );
  
        setProductsData(filteredProducts);
      }
  
  
      if (filterValue === "Hisense refrigerateur") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense refrigerateur"
        );
  
        setProductsData(filteredProducts);
      }
  
  
      if (filterValue === "Hisense climatiseur") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense climatiseur"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "Hisense machine à laver") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense machine à laver"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "Hisense micro-onde") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense micro-onde"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "Hisense lave vaisselle") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense lave vaisselle"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "Hisense cuisiniere") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense cuisiniere"
        );
  
        setProductsData(filteredProducts);
      }
  
      if (filterValue === "Hisense congelateur") {
        const filteredProducts = products.filter(
          (item) => item.category === "Hisense congelateur"
        );
  
        setProductsData(filteredProducts);
      }

    };

  const [hisenseAudioProducts, setHisenseAudioProducts] = useState([]);
  const [bestSalesProducts, setBestSalesProducts] = useState([]);
  const [hisenseRefrigerateurProducts, setHisenseRefrigerateurProducts] = useState([]);
  const [hisenseTvProducts, setHisenseTvProducts] = useState([]);
  const [hisenseMachineProducts, setHisenseMachineProducts] = useState([]);
  const [hisenseMicroProducts, setHisenseMicroProducts] = useState([]);
  const [hisenseAirProducts, setHisenseAirProducts] = useState([]);

  const year = new Date().getFullYear();


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  useEffect(()=>{
      const filteredHisenseAudioProducts = products.filter(item=> item.category
        ==='Hisense audio'
        );
      const filteredBestSalesProducts = products.filter(item=> item.category
        ==='Hisense climatiseur'
        );

      const filteredHisenseRefrigerateurProducts = products.filter(item=> item.category
        ==='Hisense refrigerateur'
        );

        const filteredHisenseTvProducts = products.filter(item=> item.category
          ==='Hisense tv'
          );

      const filteredHisenseMachineProducts = products.filter(item=> item.category
        ==='Hisense machine à laver'
        );
      const filteredHisenseMicroProducts = products.filter(item=> item.category
            ==='Hisense micro-onde'
        );

        const filteredHisenseAirProducts = products.filter(item=> item.category
            ==='Hisense air purifier'
        );

        setHisenseAudioProducts(filteredHisenseAudioProducts);
        setBestSalesProducts(filteredBestSalesProducts);
        setHisenseRefrigerateurProducts(filteredHisenseRefrigerateurProducts);
        setHisenseTvProducts(filteredHisenseTvProducts);
        setHisenseMachineProducts(filteredHisenseMachineProducts);
        setHisenseMicroProducts(filteredHisenseMicroProducts);
        setHisenseAirProducts(filteredHisenseAirProducts);
  }, []);


  return <Helmet title={"Hisense"}>
    <section className='pt-1'>
    <Carousel>
      <Carousel.Item>
        <img src={ExampleCarouselImage} text="" />
        <Carousel.Caption>
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={ExampleCarouselImage2} text=""  />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={ExampleCarouselImage3} text="" />
        <Carousel.Caption>
         
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </section>
      <button className='btn' style={{marginLeft:'20px'}}><a href="#filter">Allez vers le filtre Hisense <LuListFilter /></a></button> 
    <section className="trending__products mt-3">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>Hisense Audio</h2>
          </Col>
        <ProductsList data={hisenseAudioProducts} />          
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>Hisense Climatiseur</h2>
          </Col>
         <ProductsList data={bestSalesProducts} />     
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>Hisense Refrigerateur</h2>
          </Col>
         <ProductsList data={hisenseRefrigerateurProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>Hisense Tv</h2>
          </Col>
         <ProductsList data={hisenseTvProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>Hisense Machine à Laver</h2>
          </Col>
         <ProductsList data={hisenseMachineProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>Hisense Micro-Onde</h2>
          </Col>
         <ProductsList data={hisenseMicroProducts} /> 
        </Row>
      </Container>
    </section>
    <section className="best__sales">
      <Container>
      <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title'>Hisense Air Purifier</h2>
          </Col>
         <ProductsList data={hisenseAirProducts} /> 
        </Row>
      </Container>
    </section>
    <section className='pb-1'>
      <Image src={ExampleCarouselImage2} fluid />;
      </section>

    <section id='filter'>
        <Container>
          <Row>
            <Col lg='3' md='6' className='text-end'>
            <div className="filter__widget">
                <select onChange={handleFilter}>
                <option>Filter by Hisense</option>
                <option value="Hisense audio">Audio</option>
                  <option value="Hisense tv">tv</option>
                  <option value="Hisense refrigerateur">Refrigerateur</option>
                  <option value="Hisense climatiseur">climatiseur</option>
                  <option value="Hisense machine à laver">Machine à laver</option>
                  <option value="Hisense micro-onde">Micro-Onde</option>
                  <option value="Hisense lave vaisselle">Lave Vaisselle</option>
                  <option value="Hisense cuisiniere">Cuisiniere</option>
                  <option value="Hisense congelateur">Congelateur</option>
                </select>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    <section className='pt-0'>
        <Container>
          <Row>
            {
              productsData.length === 0 ? (
                <h1 className='text-center fs-4'>No products are found!</h1>
              ): (
                <ProductsList data={productsData} />
              )}
          </Row>
        </Container>
      </section>
    <section className="trending__products hisense">
      <Container>
        <Row>
          <Col lg='12' className='text-center mb-5'>
            <h2 className='section__title titre'> Nos Marques</h2>
          </Col>

          <Carousel1 responsive={responsive} className='text-center'>
             <Product_marqueHisense1 />
             <Product_marqueCorredo1 />
              <Product_marqueEnzahome1 />
              <Product_marqueDorco1 />
              <Product_marqueLg1 />
              <Product_marqueNutribom1 />
              <Product_marquePaco1 />
              <Product_marqueHisense1 />          
           </Carousel1>
        </Row>
      </Container>
    </section> 
    <section className="trending__products hisense" data-aos="fade-up">
      <Container>
      <Col lg='12' className='text-center mb-5 md'>
      <h2 className='section__title titre'> Showroom</h2>
          </Col>
        <Showroom />
      </Container>
    </section> 

  </Helmet>;
};


export default Hisense