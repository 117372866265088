import React from 'react'
import productHs2 from "../../assets/images/250FRUP2.jpeg";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';


function Product_Hs2() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
     <Link to='/shop/116'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productHs2} alt="" /></Link>
    <h6 className='title'>Congélateur Hisnese</h6>
    
    <Link to='/shop/116'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link>
  </div>
  )
}

export default Product_Hs2