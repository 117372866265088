import React from 'react';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './best.css';
import img1 from '../../assets/images/Sign_up_thumbnail.jpg';
import img2 from '../../assets/images/Press_media_thumbnail.jpg';
import img3 from '../../assets/images/full-shot-couple-dog-home.a65754f1aee13f0c2e83.jpg';
import { Link } from 'react-router-dom';

//LG
function Best() {


  useEffect(()=> {
    Aos.init({duration:2000});
  },[])
  return (
    <div className='best'>
        <Link to='/catalogue_fouanicongo'><h1 id='lg-title'>LG Catalogue</h1></Link>
        {/**<div>
            <p><span className='bold'>All</span></p>
            <p>Commercial</p>
            <p>Commercial</p>
            <p>Commercial</p>
        </div> */}
        <div className='container' data-aos="fade-right" >
            <img src={img1} alt=""  />
            <img src={img2} alt=""  />
            <img src={img3} alt=""  />
        </div>
        <Link to='/lg'><button className='btn-best' data-aos="fade-right">Visitez Maintenant</button></Link>
    </div>
  )
}

export default Best