import React from 'react'
import productDecakila from "../../assets/images/decakila Logo.png";//audio
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';

function Product_decakila() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productDecakila} alt="" /></Link>
  
  </div>
  )
}

export default Product_decakila