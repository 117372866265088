import React from 'react';
import './featured.css';

import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

import img11 from '../../assets/images/africa-computerelectronics-supercategory-9.jpg';
import img21 from "../../assets/images/winter-sports-concept-composition.59e504a787b9d4d098dc.jpg";
import img31 from '../../assets/images/view-woman-gas-station.b211a1d2da70eacbfa8e.jpg';
import img41 from '../../assets/images/full-shot-couple-dog-home.a65754f1aee13f0c2e83.jpg';
import img51 from '../../assets/images/corredo_banner_3.jpg';

//LG featured

function Featured() {
    useEffect(()=> {
        Aos.init({duration:2000});
      },[])
  return (
    <div className='featured'>
        <h1 className='featured-text' data-aos="fade-up">Produits haut de gamme</h1>
        <div className='container' >
            <img className='span-3 image-grid-row-2' src={img11} alt="" data-aos="fade-right"/>
            <img src={img21} alt="" data-aos="fade-up"/>
            <img src={img31} alt="" data-aos="fade-up"/>
            <img src={img41} alt="" data-aos="fade-up"/>
            <img src={img51} alt="" data-aos="fade-up"/>
            <div className='span-3 img-details'>
                <div className='top' data-aos="fade-up">
                    <h2>Consommons Originale</h2>
                    
                </div>
                <div className='info-grid'>
                    <div>
                        <div className='info'>
                            <p className='bold'>Climatiseur:</p><p style={{color:'crimson'}}>LG</p>
                        </div>
                        <div className='info'>
                            <p className='bold'>Refrigerateur:</p><p style={{color:'crimson'}}>LG</p>
                        </div>
                    </div>
                    <div>
                        <div className='info'>
                            <p className='bold'>Machine à laver:</p><p style={{color:'crimson'}}>LG</p>
                        </div>
                        <div className='info'>
                            <p className='bold'>Micro-Onde:</p><p style={{color:'crimson'}}>LG</p>
                        </div>
                        <div className='info'>
                            <p className='bold'>Télévision:</p><p style={{color:'crimson'}}>LG</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='span-2 right-img-details mb-2' data-aos="fade-left">
                <p className='mt-3'>Voici notre sélection des TV LG les plus luxueuses ou très haut de gamme, dont la proposition de valeur est largement supérieure,
                    LG TV, appareils électroménagers et climatiseurs. La vie est belle avec LG et sa gamme de produits.
                </p>
                <button className='btn'></button>
            </div>
        </div>

    </div>
  );
}

export default Featured;