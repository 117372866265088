

import React, {useRef, useEffect} from 'react';
import { IoSearch } from "react-icons/io5";
import { MdOutlineShoppingCart } from "react-icons/md";
import { IoMenu } from "react-icons/io5";

import { NavLink, useNavigate } from 'react-router-dom';
import './header.css';

import {motion} from 'framer-motion';

//import logo from '../../assets/images/eco-logo.png';
import logo_1 from '../../assets/images/LOGO_1.png';
import userIcon from '../../assets/images/user-icon.png';

import { Container, Row } from 'reactstrap';
import {useSelector} from 'react-redux';
import useAuth from '../../custom-hooks/useAuth';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase.confing'; 
import { toast } from 'react-toastify';

const nav__links = [
  {
    path:'home',
    display: 'Home',
  },
  {
    path:'lg',
    display: 'LG',
  },
  {
    path:'hisense',
    display: 'Hisense',
  },
  {
    path:'cart',
    display: 'Panier',
  },
];


const Header = () => {

  const headerRef = useRef(null);
  const totalQuantity = useSelector(state=> state.cart.totalQuantity);
  const profileActionRef = useRef(null);

  const menuRef = useRef(null);
  const navigate = useNavigate();
  const {currentUser} = useAuth();

  const stickyHeaderFunc = ()=>{
    window.addEventListener('scroll', ()=>{
      if(document.body.scrollTop > 80 || document.documentElement.scrollTop 
        > 80){
          headerRef.current.classList.add('sticky__header');
        } else{
          headerRef.current.classList.remove('sticky__header');
        }
    });
  };

  const logout = () => {

    signOut(auth).then(()=>{
      toast.success('Logged out');
      navigate("/home");
    })
    .catch((err)=>{
        toast.error(err.message);
    });
  };

  useEffect(()=> {
    stickyHeaderFunc();

    return ()=> window.removeEventListener('scroll', stickyHeaderFunc);
  });

  const menuToggle = ()=> menuRef.current.classList.toggle('active__menu');

  const navigateToCart = () => {
    navigate("/cart");
  };

  const toggleProfileActions = () => profileActionRef.current.classList.toggle("show__profileActions");

  return (
    <header className='header' ref={headerRef}>
      <Container>
        <Row>
          <div className='nav__wrapper'>
            <div className='logo'>
              <Link to='/home'><img src={logo_1} alt='logo' /></Link>
            </div>

                <div className="navigation" ref={menuRef} onClick={menuToggle}>
                  <motion.ul className="menu">

                    {nav__links.map((item, index)=>(
                        <li className="nav__item" key={index} >
                      <NavLink to={item.path} className={(navClass)=>
                      navClass.isActive ? "nav__active" : <div className='d-flex 
                      align-items-center justify-content-center
                      flex-column'>
                        <Link to='/signup'>Signup</Link>
                        <Link to='/login'>Login</Link>
  
                      </div>
                      } 
                      >
                        {item.display}
                        </NavLink>
                    </li>
                      ))
                    }

                  </motion.ul>
                </div>

                <div className="nav__icons">
                  <span className='fav__icon'><Link to='shop'>
                  < i class=""><IoSearch /></i></Link></span>
                  <span className='cart__icon' onClick={navigateToCart}>
                    <i className=""><MdOutlineShoppingCart /></i>
                    <span className='badge'>{totalQuantity}</span>
                  </span>

                  <div className='profile'>
                    <motion.img
                    whileTap={{ scale: 1.2 }} 
                    src={currentUser? 
                  currentUser.photoURL: userIcon} 
                    alt="" 
                    onClick={toggleProfileActions} />

                    <div 
                    className="profile__actions" 
                    ref={profileActionRef}
                    onClick={toggleProfileActions}
                    >
                      {currentUser ? (
                        <span onClick={logout}>Logout</span>
                        ) : (
                        <div className='d-flex 
                        align-items-center justify-content-center
                        flex-column'>
                          <Link to='/signup'>Signup</Link>
                          <Link to='/login'>Login</Link>
    
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mobile__menu">
                  <span onClick={menuToggle}>
                    <i className=""><IoMenu /></i>
                  </span>
                </div>
            </div>
          </div>
        </Row>

      </Container>
      
    </header>
  );
};

export default Header;