import React from 'react'
import productHs7 from "../../assets/images/INVERTER900BTU.png";
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import '../../styles/slider.css';

function Product_Hs7() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <Link to='/shop/166'><motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productHs7} alt="" /></Link>
    <h6 className='title'>Climatiseur Hisense</h6>
    
    <Link to='/shop/166'><motion.button className='button-slider' whileTap={{scale: 1.2}}  >Plus detail</motion.button></Link>
  </div>
  )
}

export default Product_Hs7;